body {
  background-color: #FFFFFF;
  padding: 30px;
  margin: 0;
background-color: #FFFBF0;
}
h1{
    color: #F63A3A;
    font-size: 60px;
    font-family: 'Dawning of a New Day', "Arial", sans-serif;
    margin-top: -20px;
    text-align: center;
}
h2{
    font-size: 2.5em;
}
h3{
    font-size: 1.5em;
}
p {
  display: flex;
    align-content: flex-start;
    margin-top: -0.5em;
    font-size: 13px;
    color: #7C7C7C;
}

ul {
  font-family: "Arial";
    text-align: left;
    margin-top: -20px;
}
li{
     font-family: "Arial";
    text-align: left;
    margin: 0;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #323232;
  padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
ul.header li a {
  color: #FFF;
  font-weight: 500;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
    font-family: "Avenir";
    letter-spacing: 1.5px;
}
.content {
  background-color: #FFFDF9;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.active {
  background-color: #e14b44;
}
ul{
    text-decoration: none;
}
.logo img{
    width: 100px;
}

.foods{
   
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

}

.foods img{
    padding-bottom: 20px;
}

.fb img {
    width: 2em;
}

.hello img{
    
    float: right;
    padding: 30px;
    border-radius: 50px;
}