* {
    box-sizing: border-box;
    font-family: "Arial";
}
 .App {
     max-width: 420px;
     margin: 0 auto;
}
 p {
     text-align: center;
     font-weight: 500;
}
 input[type=text],input[type=email], select, textarea {
     width: 100%;
     padding: 12px;
     border: 1px solid #ccc;
     box-sizing: border-box;
     margin-top: 6px;
     margin-bottom: 16px;
     resize: vertical;
}
 input[type=submit] {
     background-color: #A9A9A9;
     color: white;
     padding: 12px 20px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
}
 input[type=submit]:hover {
     background-color: #F59595;
}
 .container {
     border-radius: 5px;
     background-color: #f2f2f2;
     padding: 20px;
}